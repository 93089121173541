import React from 'react'
import Footer from 'components/commercetools-ui/footer'
import { FooterProps } from 'types/footer'

interface FooterTasticProps {
  data: {
    [key: string]: unknown
  } & FooterProps
}

const FooterTastic: React.FC<FooterTasticProps> = ({ data }) => {

  return (
    <div className="fixed-screen-width lg:relative-width">
      <Footer {...data} />
    </div>
  )
}

export default FooterTastic
